export const isEnabled = (feature: string | undefined) => {
  if (feature === "true") return true

  return false
}

export function toFloat(val: string | undefined) {
  if (!val) return undefined

  try {
    return parseFloat(val) || undefined
  } catch {
    return undefined
  }
}

export function toInt(val: string | undefined) {
  if (!val) return undefined

  try {
    return parseInt(val) || undefined
  } catch {
    return undefined
  }
}
