import { BrandsEnum, Environment, ILogLevel } from "../../types"
import { toFloat, toInt, isEnabled } from "./helpers"

/**
 * @description Use it only when you have no access to react context.
 * If you need runtime config in react component/hook use `usePublicRuntimeConfig` instead.
 */
export function getRuntimeConfig() {
  return {
    /** App */
    ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT as Environment,
    MANDATOR_NUMBER: process.env.NEXT_PUBLIC_MANDATOR_NUMBER as BrandsEnum,
    SERVICE_FEE_VALUE: toFloat(process.env.NEXT_PUBLIC_SERVICE_FEE_VALUE),
    RELEASE_VERSION: process.env.NEXT_PUBLIC_RELEASE_VERSION,
    /** Contentful */
    CONTENTFUL_SPACE_ID: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    CONTENTFUL_ENVIRONMENT: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
    CONTENTFUL_ACCESS_TOKEN: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
    DRAFT_MODE: isEnabled(process.env.NEXT_PUBLIC_DRAFT_MODE),
    /** Endpoints and urls */
    BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
    QPROXY_BASE_URL: process.env.NEXT_PUBLIC_QPROXY_BASE_URL,
    ADVICE_PLATFORM_API: process.env.NEXT_PUBLIC_ADVICE_PLATFORM_API,
    ADVICE_PLATFORM_API_VERSION:
      process.env.NEXT_PUBLIC_ADVICE_PLATFORM_API_VERSION,
    ADVICE_PORTAL_URL: process.env.NEXT_PUBLIC_ADVICE_PORTAL_URL,
    SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    AWS_FRAUD_DETECTOR_URL: process.env.NEXT_PUBLIC_AWS_FRAUD_DETECTOR_URL,
    FINGERPTINTS_PROXY: process.env.NEXT_PUBLIC_FINGERPTINTS_PROXY,
    /** Tokens and keys */
    ADYEN_WEB_CLIENT_KEY: process.env.NEXT_PUBLIC_ADYEN_WEB_CLIENT_KEY,
    UC_SETTINGS_ID: process.env.NEXT_PUBLIC_UC_SETTINGS_ID,
    FETCHIFY_API_KEY: process.env.NEXT_PUBLIC_FETCHIFY_API_KEY,
    FINGERPRINTJS_API_KEY: process.env.NEXT_PUBLIC_FINGERPRINTJS_API_KEY,
    AWS_FRAUD_DETECTOR_KEY: process.env.NEXT_PUBLIC_AWS_FRAUD_DETECTOR_KEY,
    FAPCAS_API_KEY: process.env.NEXT_PUBLIC_FAPCAS_API_KEY,
    RUDDERSTACK_WRITE_KEY: process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY,
    X_TRACKING_KEYS: process.env.NEXT_PUBLIC_X_TRACKING_KEYS,
    SEPA_CREDITOR_ID: process.env.NEXT_PUBLIC_SEPA_CREDITOR_ID,
    /** Feature flags */
    PHONE_VERIFICATION_FOR_CHAT_IS_ENABLED: isEnabled(
      process.env.NEXT_PUBLIC_PHONE_VERIFICATION_FOR_CHAT_IS_ENABLED
    ),
    FINGERPRINTS_IS_ENABLED: isEnabled(
      process.env.NEXT_PUBLIC_FINGERPRINTS_IS_ENABLED
    ),
    AWS_FRAUD_DETECTOR_IS_ENABLED: isEnabled(
      process.env.NEXT_PUBLIC_AWS_FRAUD_DETECTOR_IS_ENABLED
    ),
    EXPERT_ADS_BANNER_MOBILE_VIEW_IS_ENABLED: isEnabled(
      process.env.NEXT_PUBLIC_EXPERT_ADS_BANNER_MOBILE_VIEW_IS_ENABLED
    ),
    EXPERT_ADS_BANNER_DESKTOP_VIEW_IS_ENABLED: isEnabled(
      process.env.NEXT_PUBLIC_EXPERT_ADS_BANNER_DESKTOP_VIEW_IS_ENABLED
    ),
    SENTRY_IS_ENABLED: isEnabled(process.env.NEXT_PUBLIC_SENTRY_IS_ENABLED),
    FRESH_CHAT_IS_ENABLED: isEnabled(
      process.env.NEXT_PUBLIC_FRESH_CHAT_IS_ENABLED
    ),
    WIZARD_IS_ENABLED: isEnabled(process.env.NEXT_PUBLIC_WIZARD_IS_ENABLED),
    SERVICE_FEE_IS_ENABLED: isEnabled(
      process.env.NEXT_PUBLIC_SERVICE_FEE_IS_ENABLED
    ),
    FREE_CHAT_IS_ENABLED: isEnabled(
      process.env.NEXT_PUBLIC_FREE_CHAT_IS_ENABLED
    ),
    RUDDERSTACK_IS_ENABLED: isEnabled(
      process.env.NEXT_PUBLIC_RUDDERSTACK_IS_ENABLED
    ),
    VOUCHERS_FOR_CALLBACKS_ENABLED: isEnabled(
      process.env.NEXT_PUBLIC_VOUCHERS_FOR_CALLBACKS_ENABLED
    ),
    /** Timers */
    EXPERT_ONLINE_STATUS_INTERVAL_MS: toInt(
      process.env.NEXT_PUBLIC_EXPERT_ONLINE_STATUS_INTERVAL_MS
    ),
    TRACKED_SESSION_EXPIRES_IN: toInt(
      process.env.NEXT_PUBLIC_TRACKED_SESSION_EXPIRES_IN
    ),
    /** Fresh chat */
    FRESHCHAT_WIDGET_URL: process.env.NEXT_PUBLIC_FRESHCHAT_WIDGET_URL,
    FRESH_CHAT_TOKEN: process.env.NEXT_PUBLIC_FRESH_CHAT_TOKEN,
    FRESH_CHAT_RESTRICTED_PAGES:
      process.env.NEXT_PUBLIC_FRESH_CHAT_RESTRICTED_PAGES,
    /** Revive */
    REVIVE_ID: process.env.NEXT_PUBLIC_REVIVE_ID,
    REVIVE_CENTER_MOBILE_ZONE_ID:
      process.env.NEXT_PUBLIC_REVIVE_CENTER_MOBILE_ZONE_ID,
    REVIVE_CENTER_DESKTOP_ZONE_ID:
      process.env.NEXT_PUBLIC_REVIVE_CENTER_DESKTOP_ZONE_ID,
    REVIVE_LEFT_DESKTOP_ZONE_ID:
      process.env.NEXT_PUBLIC_REVIVE_LEFT_DESKTOP_ZONE_ID,
    SERVICE_CENTER_PHONE_NUMBERS:
      process.env.NEXT_PUBLIC_SERVICE_CENTER_PHONE_NUMBERS,
    LOGS_LEVEL: process.env.NEXT_PUBLIC_LOGS_LEVEL as ILogLevel,
  }
}

export type IPublicRuntimeConfig = ReturnType<typeof getRuntimeConfig>
